import Vue from 'vue'
import VueRouter from 'vue-router'
/* import HomeView from '../views/HomeView.vue' */

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
     component: () => import(/* webpackChunkName: "about" */ '../components/HelloWorld.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:id',
    name: 'colaboradores',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MainColaboradores.vue')
  }
]

const router = new VueRouter({
  //mode: 'history',
  routes
})

export default router